.btn {
    text-transform: uppercase;
    @include button-size($btn-padding-y, $btn-padding-x, .85rem, $btn-line-height, $btn-border-radius);

    &.btn-arrow-right {
        &::after {
            content: url('images/icons/right-arrow.svg');
            width: 16px;
            height: 16px;
            margin-left: 10px;
            display: inline-block;
            vertical-align: sub;
        }
    }
}