#section3{
    .slick-prev{
        left: -50px;
    }
    .slick-next{
        right: -50px;
    }
    .slick-prev,
    .slick-next {
        background: transparent;
        border: unset;
    }
    .slick-prev:before, .slick-next:before{
        color: #00ad97;
    }
    .slick-track{
        // border: 1px solid red;
        // width: 90%!important;
    }
}
.section.experiencia{

    background: #ffffff;
    // background-image: url('images/experiencia-bg.jpg');


    // background-size: cover;
    // background-position: center center;
    // background-repeat: no-repeat;

    @media (max-width: 776px){
        // border: 1px solid red;
        background-size: auto 100%;
        background-position: left ;
        background-repeat: no-repeat;
    }

    .laptop{
        // border: 1px dashed black;
        width: 60vw;
        margin-top: -100px;
        @media (max-width: 1366px){
            // border: 1px solid black;
            width: 60vw;
            margin-top: -80px;
        }
        @media (max-width: 768px){
            // border: 1px dashed orange;
            width: 100vw;
            margin-top: unset;
        }
        img{
            width: 100%;
        }
    }

    .xp-itens{
        // border: 1px solid red;
        margin-top: -80px;
        width: 70vw;

        @media (max-width: 768px){
            width: 100vw;
            margin-top: unset;
        }
        .xp-item{
            // border: 1px solid blue;
            .icon{
                // border: 1px solid black;
                margin-bottom: 15px;
                text-align: center;
                img{
                    @media (max-width: 1366px){
                        width: 48px;
                    }
                    @media(max-width: 768px){
                        margin: 0 auto;
                    }
                }
            }
            h4{
                // border: 1px dashed fuchsia;
                color: #2b2b2b;
                font-weight: bold;
                font-size: 1em;
                line-height: 1.3em;
            }
            p{
                color: #7b7b7b;
                font-size: 0.8em;
                line-height: 1.4em;
            }
        }

    }
}