/*
* 	Pagination page
* 	pagonation.less
*/
.paging span
{
  margin-left: 15px;
}


    .pagination {
        padding: 0px;

        li.btn-jump a
        {
            padding: 2px 20px;
            font-weight: 700;
        }

        li.disabled a
        {
            color: $gray_color;
            padding: 2px 20px;
            cursor: default;
            &:hover
            {
                color: $gray_color;
            }
        }

        li.active
        {
            padding: 0px 4px;
            // font-weight: 700;
            // border-bottom: 4px solid $dark_base_color!important;
            color: $primary_base_color;
            // background: $primary_base_color;
            margin: 0px 12px;

            border-bottom: 2px solid $primary_base_color;
        }

        li.number a,
        li.number span
        {
            display: block;
            padding: 0px 4px;
            margin: 0px 12px;
        }

        li {
            display: inline-block;
            list-style: none;
            text-decoration: none;
            font-weight: 600;
            font-size: 16px;
            a {
                display: block;
                // padding: 2px;
                color: inherit;
                @include transition(all .1s ease-in-out);
                &:hover
                {
                    color: $primary_base_color;
                }
            }
        }
    }

@include media-breakpoint-down(lg) {
    .pagination .number {
        display: none;
    }
}