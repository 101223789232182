.section.home{
    background: #000000;
    background-image: url('images/card01.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .napp-a{
        // border: 1px dashed fuchsia;
        // @media (min-width: 1400px){
        //     max-width: 100%;
        //     height: auto;
        // }
        // @media (max-width: 1366px){
        //     width: 580px;
        // }
    }
}