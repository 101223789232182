.mini-float-wrapper.show-backto-top
{
    bottom: 7px;
    @include transition(all .2s ease);
}
.btn-floating-top-wrapper.show-backto-top
{
    bottom: 50px;
    @include transition(all .2s ease);
}

.mini-float-wrapper
{
    // border: 1px solid red;
    position: fixed;
    bottom: -100px;
    right: 10px;
    width: 44px;
    z-index: 9999;
    @include transition(all .2s ease);

    .caret-down {
        text-align: center;
        width: 100%;
        color: white;
        height: 44px;
        // text-transform: uppercase;
        line-height: 40px;
    }
    .mini-float {
        // border: 1px solid red;
        width: 44px;
        height: 44px;
        // background-color:#4E5D72;
        border-radius:50%;
        z-index: 100;
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
        background: $primary_base_color;
        border: 2px solid $light_color;
        .caret-down {
        }
    }
}

.btn-floating-top-wrapper
{
    // border: 1px solid blue;
    position: fixed;
    // bottom: 190px;
    bottom: -200px;
    right: 0px;
    width: 150px;
    z-index: 9999;
    @include transition(all .2s ease);
    .image
    {
        // border: 1px solid red;
        text-align: center;
        img
        {
            // border: 1px solid yellow;
            height: auto;
            text-align: center;
        }
    }
    .back {
        // border: 1px solid olive;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        text-align: center;
        color: $yellow;
        text-transform: uppercase;
    }
    .caret-down {
        text-align: center;
        width: 100%;
        color: $yellow;
        text-transform: uppercase;
    }

    .btn-floating-top {
        // border: 1px solid red;
        width: 80px;
        height: 80px;
        // background-color:#4E5D72;
        border-radius:50%;
        z-index: 100;
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
        background: $yellow;

        .caret-down {
        }
    }
    &:hover
    {
        .btn-floating-top {
            background: $primary_base_color;
            // background: $yellow url('../imagens/sky.jpg') bottom center repeat-y;
            // animation: top_to_bottom 3s linear infinite;
        }
        .back,
        .caret-down
        {
            // color: red;
        }
        .caret-down,
        .back
        {
            // animation: pulse 1.60s infinite;
        }
        img {
            animation: shake 6.82s infinite;
        }
    }
}
@keyframes top_to_bottom{
    from{
      background-position: 0 0;
    }
    to{
      background-position: 0 350px;
    }
  }

@-webkit-keyframes top_to_bottom{
    from{
      background-position: 0 0;
    }
    to{
      background-position: 0 350px;
    }
  }