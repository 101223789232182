.section.eficiencia {
    background: #000000;
    background-image: url('images/bg_page_2.jpg');
    background-position: bottom center;
    background-size: cover;

    .dm-width {
        width: 680px;
        max-width: 100%;
    }

    .tablet-mockup {
        position: relative;
        z-index: 5;
    }

    .dm-device {
        position: relative;
        width: 100%;
        padding-bottom: 93.47%;
    }

    .device {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        -webkit-background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('images/tablet.png');
        background-size: contain;
        background-position: center center;

    }

    .screen {
        overflow: hidden;
        position: absolute;
        top: 25%;
        bottom: 18%;
        left: 16%;
        right: 10%;
    }

    .slick-slide div,
    .slick-slide div .item,
    .slick-slider,
    .slick-list,
    .slick-track {
        height: 100%;
    }

    .item {
        width: 100%;
        display: flex !important;
        justify-content: center;
        align-items: center;
        background-image: url('images/bg_tablet_slider.png');
        background-position: center;
        background-size: cover;
    }

    .item .main-screen {
        background-image: url('images/tablet_main_screen.jpg');
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100%;
    }

    .item .texts {
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: 0px 30px;
    }


    .item .title {
        color: #2b2b2b;//$dark;
        font-weight: bold;
        font-family: 'Montserrat';
        margin-bottom: 10px;
    }

    .item .text {
        color: #fff;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 22px;
    }


    .topics .topic {
        // border: 1px solid red;
        // font-weight: bold;
        color: #313131;
        display: flex;
        align-items: center;
        font-size: 0.95em;
        @include transition(all .2s ease-in);
        &::before {
            content: '';
            width: 20px;
            height: 1px;
            background-color: $gray-600;
            // position: relative;
            // display: inline-block;
            // vertical-align: middle;
            margin-right: 5px;
        }
    }

    .topics .topic.active {
        color: $primary_base_color;
        &::before {
            // background-color: $primary_base_color;
        }
    }

    @media (min-width: 992px) and (max-height: 700px) {

        .dm-width {
            width: 580px;
        }
        .item .title {
            font-size: 24px;
        }
        .item .text {
            font-size: 16px;
        }
    }

    @media (max-width: 992px) {
        .dm-device {
            padding-bottom: 140%;
        }

        .device {
            background-image: url('images/tablet_portrait.png');
        }

        .screen {
            top: 10%;
            bottom: 17%;
            left: 18%;
            right: 10%;
        }

        .item .texts {
            padding: 0px 10px;
            .title {
                font-size: 16px;
            }
            .text {
                font-size: 14px;
            }
        }
        .dm-width {
            margin: auto;
        }
    }
    @media (min-width: 992px) {
        .dm-width {
            margin-left: auto;
        }

    }

}

.section.eficiencia.en{
    .item .main-screen {
        background-image: url('images/tablet_main_screen_ing.jpg');
    }
}