//_form_utils.scss
// textarea,
// input[type="text"],
// input[type="password"],
// input[type="datetime"],
// input[type="datetime-local"],
// input[type="date"],
// input[type="month"],
// input[type="time"],
// input[type="week"],
// input[type="number"],
// input[type="email"],
// input[type="url"],
// input[type="search"],
// input[type="tel"],
// input[type="color"],
// .uneditable-input
// {
//     // -webkit-box-shadow: none;
//     // -moz-box-shadow: none;
//     // box-shadow: none;
//     color: $gray_color;
// }

// .form-control,
// .form-control:focus,
// textarea:focus,
// input[type="text"]:focus,
// input[type="password"]:focus,
// input[type="datetime"]:focus,
// input[type="datetime-local"]:focus,
// input[type="date"]:focus,
// input[type="month"]:focus,
// input[type="time"]:focus,
// input[type="week"]:focus,
// input[type="number"]:focus,
// input[type="email"]:focus,
// input[type="url"]:focus,
// input[type="search"]:focus,
// input[type="tel"]:focus,
// input[type="color"]:focus,
// input[type="file"]:focus,
// .uneditable-input:focus
// {
//     outline: 0;
//     outline: thin dotted \9;
//     /* IE6-9 */
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//     box-shadow: none;

// }

// input[type="number"],
// input[type="number"]:focus,
// .input-number,
// .input-number:focus
// {
//     color: $secondary_base_color;
//     // font-weight: bold;
//     border: transparent;
//     border-bottom: 1px solid $secondary_base_color;
// }
// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
// input[type="number"] {
//     -moz-appearance: textfield;
// }


form .row
{
    // border: 1px solid magenta;
    // margin-bottom: 10px;
}

.form-group {
    .form-control {

        &::placeholder,
        &::placeholder {
            color: $white;
            // font-size: 1.5em;
        }
        border: transparent;
        border-bottom: 1px solid $white;

    }
}

.required-legend
{
    text-align: left;
    i{
        color: $red_color;
        font-size: 11px;
    }
}

.form-control.not-required
{
    // border: 1px solid red;
    // width: calc(100% - 10px) !important;
}

.required-icon
{

    .form-control
    {
        // border: 1px solid purple;
        display: inline-block !important;
        width: calc(100% - 10px) !important;
    }

    i {
        // border: 1px solid blue;
        width: 5px;
        color: $red_color;
        padding: 0px 0px 0px 5px;
        font-size: 11px;
    }
}

.error-message
{
    padding: 5px;
    color: $red_color;
    font-weight: 600;
    font-size: 0.8em;
    text-align: left;
    line-height: 1.2em;
}

select.form-error
{
    border: 1px solid $red_color!important;
}

.error
{
    input,
    textarea
    {
        border: 1px solid $red_color!important;
    }
}


// flash
div #flashMessage
{
    padding: 20px;
    font-weight: 700;
    background: transparentize($red_color, 0.7);
    color: white;
}

div #flashMessage .message
{

}


.flash.flash-failure
{
    background: lighten($red_color, 15%);
    color: $light_color;
}
.flash.flash-success
{
    background: lighten($green_color, 15%);
    color: $light_color;//$green_color;
}
.flash
{
    // border: 1px solid red;
    padding: 10px 5px;
    margin: 10px 0px;
    font-weight: 700;
    text-align: center;
}
.prodFLash {
    z-index: 100;
}

.form-datetime-separator
{
    color: $green_color;
    font-weight: bold;
    font-size: 24px;
    margin: 0px 5px;
}

.field-inline
{
    display: inline-block!important;
    width: auto!important;
}