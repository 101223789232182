
$slick-font-family: "FontAwesome";
$slick-dot-size: 1.75rem;
$slick_arrow_font_size: .95rem;
$slick-dot-color-active: $primary_base_color;
$slick-dot-color: $white;
$slick-arrow-color: $light_color;
$slick-opacity-not-active: 0;
$slick-opacity-disabled: .7;
$slick-opacity-default: 1;

$slick-prev-character: "\f053";
$slick-next-character: "\f054";
