@font-face {
	// font-family: "BebasKai";
	// src:
	// 	url("fonts/bebaskai-webfont.woff2") format("woff2"),
	// 	url("fonts/bebaskai-webfont.woff") format("woff"),
	// 	url("fonts/bebaskai.ttf") format("truetype");
		//  url("fonts/Samplino.ttf") format("truetype"),
		//  url("fonts/Samplino.svg#Samplino") format("svg");
}

.font-border {
	// border-color: $primary_base_color;
	position: relative;
	z-index: 1;
	&::after {
		content: '';
		width: 100%;
		height: 3px;
		background-color: $primary_base_color;
		position: absolute;
		left: 0;
		bottom: 6px;
		z-index: -1;
	}
	// border-bottom: 3px solid $primary_base_color;
}

.font-small {
	font-size: 80%;
	letter-spacing: 1px;
	opacity: .8;
}

.font-opacity {
	opacity: .8;
	// letter-spacing: 1px;
}
.font-roboto {
	font-family: 'Roboto';
	font-size: .8rem;
	line-height: 1.4em;
}

.line-height {
	line-height: 1.75;
}

.font-playfair {
	font-family: 'Playfair Display';
	&.italic {
		font-style: italic;
	}
	font-size: 2rem;
}
.font-montserrat {
	font-family: 'Montserrat';
	font-weight: 700;
	&.light {
		font-weight: 300;
	}
	&.italic {
		font-style: italic;
	}
	&.big {
		font-size: 3.25rem;
	}
	font-size: 2rem;
}