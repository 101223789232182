// @import "wordpress_base";
@import '_variables';
@import '_bootstrap_variables';
@import 'fonts';
@import 'vendor/bootstrap/bootstrap';
@import 'vendor/font-awesome/font-awesome';
@import '_slick_variables';
@import 'vendor/slick';
@import 'vendor/slick-theme';
@import 'vendor/lity';
@import 'vendor/openlayers';
@import 'vendor/jquery.fullpage';
@import '_mixins';
@import '_buttons';
@import '_animations';
@import "_back-to-top";
@import '_form_utils';
@import 'formularios';
@import '_pagination';
@import 'type';
@import 'vendor/jquery.fullpage';
@import 'vendor/jquery.sidr.dark.scss';
// @import 'header';
// @import 'footer';
// @import 'home';
// @import 'mapa';
// @import 'artigos';

@import 'home';
@import 'experiencia';
@import 'revolucao';
@import 'section_eficiencia';
@import 'produtos';
@import 'contato';


#preloader {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: #000000;
     z-index: 99999999999999999999999999;
     display: flex;
     justify-content: center;
     align-items: center;
}
 #status{
    // border: 1px solid red;
    width: 60px;
     height: 60px;
     position: absolute;
     z-index: 9999999999999999999999999999999999999;
    //  left: 50%;
    //  top: 50%;
    .dashed {
        stroke-dasharray: 10;
    }
    .path {
        stroke: #58B958;
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: dash 5s linear alternate, colorfill 0.5s ease-out 5s forwards;
    }
    .fill{
        animation: colorfill 0.5s ease-out 5s forwards;
    }
      @keyframes dash {
        from {
          stroke-dashoffset: 1000;
        }
        to {
          stroke-dashoffset: 0;
        }
      }
      @keyframes colorfill {
        0%{
            fill: transparent;
            stroke-dashoffset: 0;
        }
        100%{
            fill: #58b958;
            stroke-dashoffset: 0;
        }
      }

      @keyframes glowing {
        0% { box-shadow: 0 0 -10px #58b958; }
        40% { box-shadow: 0 0 20px #58b958; }
        60% { box-shadow: 0 0 20px #58b958; }
        100% { box-shadow: 0 0 -10px #58b958; }
      }

}
 body {}


.z-index-100 {
    z-index: 100;
}


.roboto{
    font-family: 'Roboto', sans-serif;
}
.montserrat{
    font-family: 'Montserrat', sans-serif;
}
.playfair{
    font-family: 'Playfair Display', serif;
}
.light{
    font-weight: 100;
}

.fp-viewing-eficiencia,
.fp-viewing-experiencia{
    #fp-nav ul li a span,
    .fp-slidesNav ul li a span{
        background: #000000;
    }
    #fp-nav ul li a.active span,
    .fp-slidesNav ul li a.active span{
        background: #58B958;
    }
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span{
    background: white;
}
.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  .pace-inactive {
    display: none;
  }

  .pace .pace-progress {
    background-color: rgba(0, 0, 0, 0.19999999999999996);
    position: fixed;
    z-index: -1;
    top: 0;
    right: 100%;
    bottom: 0;
    width: 100%;
  }

.img-bandeira{
    svg{
        width: 100px;
        height: 20px;
    }
}

header{
    // @extend .debug;
    width: 100%;
    position: absolute;
    z-index: 999;
    .logo{
        // border: 1px dashed black;
        width: 200px;
        svg{
            // border: 1px solid red;
            width: 100%;
            height: 50px;
            path { transition: fill .4s ease; }
        }
    }
    .navbar-light .navbar-nav .nav-link{
        min-width: 150px;
        text-align: center;
    }
    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        transition: all .4s ease;
    }
    .navbar-toggler{
        outline: none;
    }
    .nav-link{
        font-size: 0.9em;
        font-weight: 500;
    }
    .img-bandeira{
        svg{
            width: 100px;
            height: 20px;
        }
    }
}

header.light{
    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        transition: all .4s ease;
    }
    .navbar-light .navbar-toggler{
        // border-color: white;
    }
    .navbar-light .navbar-nav .nav-link{
        color: white;
        transition: all .4s ease;
    }
    .lightlogo{
        svg{
            path{
                fill: #ffffff;
            }
        }
    }
}

.rodape-lightlogo{
    svg{
        path{
            fill: #ffffff;
        }
    }
}

.fullpage{

}
.section{
    // border: 3px dashed greenyellow;
}
.section1{
    background: #cccccc;
}

.section2{
    background: #cccccc;
}

.section3{
    background: #cccccc;
}

.section4{
    background: #cccccc;
}

.verde{
    color: #58b958;
}
.amarelo{
    color: #ffc72a;
}
.roxo{
    color: #822c93;
}
.verde-oceano{
    color: #00afab;
}
.vermelho{
    color: #ff4e00;
}




// ===================================================================== >


a {
    outline: unset;
    &:hover {
        text-decoration: none;
    }
}

.font-weight-semibold {
    font-weight: 600;
}
.spacer-10 {
    height: 10px;
}
.spacer-20 {
    height: 20px;
}
.spacer-50 {
    height: 50px;
}
.spacer-100 {
    height: 100px;
}
.spacer-200 {
    height: 200px;
}


.text-error {
    color: red;
    font-style: italic;
    font-size: 12px;
    min-height: 16px;
}

.debug {
    border: 1px solid fuchsia;
}

.pointer {
    cursor: pointer;
}

.slick-prev {
    left: 0px;
}
.slick-next {
    right: 0px;
}


.overflow-hidden {
    overflow: hidden;
}

.post_thumbnail-wrapper {
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

// .post-edit-link {
//     background-color: $primary_base_color;
//     padding: 10px;
//     color: $white;
//     &:hover {
//         color: $light;
//         background-color: darken($primary_base_color, 5%);
//     }
// }


@include media-breakpoint-down(lg) {
    .spacer-200 {
        height: 80px;
    }
}