
// contato section ======================================================>
// ======================================================================>
.section.contato{
    background: #000000;
    background-image: url('images/contato.jpg');

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    /*.contato-num{
        : 20px;
    }*/


    @media (max-width: 776px){
        // background-size: auto 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .logo{
        text-align: center;
        @media (max-width: 776px){
            svg{
                width: 60%;
            }
        }
    }
    .location, a{
        color: white;
        font-size: 21px;
    }
}