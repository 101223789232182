
.bottom-separator {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        width: 40px;
        height: 2px;
        background-color: $primary_base_color;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -15px;
    }
}

