// container sizes
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1280px,
    xxl: 1440px,
    // xxxl: 1840px
  );

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  // xxxl: 1800px
);

// Spacing ============================================================================ />
$spacers: () !default;
$spacer: 1rem;

$spacers: map-merge(
  (
    6: ($spacer * 3.5),
    7: ($spacer * 4),
    8: ($spacer * 4.5),
    9: ($spacer * 5),
  ),
  $spacers
);


$primary: $primary_base_color;
$secondary: $secondary_base_color;
$more_gray_color: $gray_color;

$enable-shadows: true;

// Type
$font-family-base: 'Open Sans';
$headings-font-family: 'Open Sans';

$font-size-base:              1rem;
// $font-size-lg:                ($font-size-base * 1.25) !default;
// $font-size-sm:                ($font-size-base * .875) !default;

// $font-weight-light:           300 !default;
// $font-weight-normal:          400 !default;
// $font-weight-bold:            700 !default;

// $font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.2;
$headings-line-height: 1.1;

$border-radius: 8px;


// Navbar
$navbar-toggler-border-radius: 6px;
$navbar-toggler-border-radius: 2px;
$navbar-dark-color: $dark_color;
$navbar-dark-active-color: lighten($dark_color, 30%);
$navbar-dark-hover-color: darken($primary_base_color, 10%);
$navbar-dark-toggler-border-color: $dark_color;
$dropdown-border-radius: 0;


//navs-tabs
// $nav-tabs-link-active-bg: $light_color;

// Btns
$btn-border-radius: 0;
$btn-box-shadow: 0px 8px 18px lighten($primary_base_color, 5%);
$btn-focus-box-shadow: 0px 8px 18px lighten($primary_base_color, 5%);
$btn-active-box-shadow: 0px 8px 14px lighten($primary_base_color, 5%);
$btn-font-weight: 500;
$btn-padding-y:               .65em;
$btn-padding-x:               1.75rem;
// $btn-line-height:       3;
// $btn-link-disabled-color:     $light_color;



$btn-focus-width: 0;


// Inputs
$input-btn-line-height: 1.5;
$input-focus-border-color: $light_color;
$input-box-shadow: 0;
$input-focus-box-shadow: none;
$input-bg: transparent;
$input-color: $light_color;



// Tooltips
// $tooltip-color: $light_color;
$tooltip-bg: $primary_base_color;
$tooltip-padding-y: 6px;
$tooltip-padding-x: 6px;

// Forms
$input-border-radius: 0;
// $input-border-color: lighten($secondary_base_color, 30%);


// Modal
$modal-lg:                          1200px !default;
$modal-md:                          800px !default;
$modal-sm:                          400px !default;


$body-bg:                   #000000;

$link-color:                #000000;


$hambuguer_menu_lines: rgba(255, 255, 255, 1.0);
$navbar-light-toggler-border-color: rgba(0,0,0, 0.0);