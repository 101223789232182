.section.produtos{
    background: #000000;
    background-image: url('images/produtos.jpg');


    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media (max-width: 776px){
        // border: 1px solid red;
        // background-size: auto 100%;
        background-size: cover;
        background-position: left ;
        background-repeat: no-repeat;
    }

    .heading{
        svg{
            width: 94%;
            path{
                // fill: white;
            }
        }
    }
    .prod{

        .icon{
            img{
                height: 88px;
            }
        }
        .text{
            h4{
                color: white;
                font-weight: bold;
                font-size: 1em;
                line-height: 1.3em;
            }
            p{
                color: white;
                font-size: 0.8em;
                line-height: 1.4em;
            }
        }
    }
}