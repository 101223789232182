@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
  // transition-timing-function: ease-out;
}

@mixin transform($transforms) {
	   -moz-transform: $transforms !important;
	     -o-transform: $transforms !important;
	    -ms-transform: $transforms !important;
	-webkit-transform: $transforms !important;
          transform: $transforms !important;
}


@mixin blur($px: 0) {
    -webkit-filter: blur(#{$px}px) !important;
    -ms-filter: blur(#{$px}px) !important;
    -moz-filter: blur(#{$px}px) !important;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'blur\'><feGaussianBlur stdDeviation=\'#{$px}\' /></filter></svg>#blur");
    filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='#{$px}');
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// Single side border-radius

@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
     border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
     border-top-left-radius: $radius;
     background-clip: padding-box;
}